import { createBrowserRouter } from "react-router-dom";
import { HomePage } from "./Pages/Home";
import {
  Awards,
  Board,
  Chairman,
  History,
  Organization,
  Vision,
} from "./Pages/AboutUs";
import {
  NewsClipping,
  NewsUpdate,
  Press,
  Public,
  Set,
  SetNews,
} from "./Pages/News";
import { Anti, Corporate, Csr } from "./Pages/Sustainability";
import { Complaint, Contact, Email, FAQs, Inquiry } from "./Pages/ContactUs";
import {
  Investor,
  Multimedia,
  Analysis,
  ContactInvestor,
  FinancialStatement,
  FinancialHiglights,
  FinancialMda,
  GeneralMeeting,
  MajorShareholder,
  DividendPolicy,
  IRCalendar,
  StockPrice,
  FinancialDownload,
} from "./Pages/IR";
import { RDetail1, RDetail2 } from "./Pages/News/DetailNews";
import { AnnualReport, Form_56_1 } from "./Pages/IR/AnnualReport_Form56-1";
import CookiePolicy from "./Pages/Other/CookiePolicy";
import { PrivacyPolicy } from "./Pages/Other";
import ReadDetail from "./Pages/IR/ShareholderInformation/ReadDetail/ReadDetail";
import App from "./App";

export const Router = createBrowserRouter(
  [
    {
      path: "/",
      element: <App />,
      errorElement: "",
      children: [
        {
          path: "/",
          element: <HomePage />,
        },
        {
          path: "/Chairman",
          element: <Chairman />,
        },
        {
          path: "/History",
          element: <History />,
        },
        {
          path: "/Vision",
          element: <Vision />,
        },
        {
          path: "/Organization",
          element: <Organization />,
        },
        {
          path: "/Board",
          element: <Board />,
        },
        {
          path: "/Awards",
          element: <Awards />,
        },
        {
          path: "/Set",
          element: <Set />,
        },
        {
          path: "/Public",
          element: <Public />,
        },
        {
          path: "/Press",
          element: <Press />,
        },
        {
          path: "/NewsUpdate",
          element: <NewsUpdate />,
        },
        {
          path: "/RDetail1",
          element: <RDetail1 />,
        },
        {
          path: "/RDetail2",
          element: <RDetail2 />,
        },
        {
          path: "/NewsClipping",
          element: <NewsClipping />,
        },
        {
          path: "/Corporate",
          element: <Corporate />,
        },
        {
          path: "/Anti",
          element: <Anti />,
        },
        {
          path: "/Csr",
          element: <Csr />,
        },
        {
          path: "/Investor",
          element: <Investor />,
        },
        {
          path: "/Multimedia",
          element: <Multimedia />,
        },
        {
          path: "/Analysis",
          element: <Analysis />,
        },
        {
          path: "/ContactInvestor",
          element: <ContactInvestor />,
        },
        {
          path: "/Contact",
          element: <Contact />,
        },
        {
          path: "/Inquiry",
          element: <Inquiry />,
        },
        {
          path: "/Complaint",
          element: <Complaint />,
        },
        {
          path: "/Email",
          element: <Email />,
        },
        {
          path: "/FAQs",
          element: <FAQs />,
        },
        {
          path: "/financial_statement",
          element: <FinancialStatement />,
        },
        {
          path: "/financial_highlights",
          element: <FinancialHiglights />,
        },
        {
          path: "/financial_mda",
          element: <FinancialMda />,
        },
        {
          path: "/financial_download",
          element: <FinancialDownload />,
        },
        {
          path: "/AnnualReport",
          element: <AnnualReport />,
        },
        {
          path: "/Form_56_1",
          element: <Form_56_1 />,
        },
        {
          path: "/GeneralMeeting",
          element: <GeneralMeeting />,
        },
        {
          path: "/MajorShareholder",
          element: <MajorShareholder />,
        },
        {
          path: "/DividendPolicy",
          element: <DividendPolicy />,
        },
        {
          path: "/IRCalendar",
          element: <IRCalendar />,
        },
        {
          path: "/StockPrice",
          element: <StockPrice />,
        },
        {
          path: "/CookiePolicy",
          element: <CookiePolicy />,
        },
        {
          path: "/PrivacyPolicy",
          element: <PrivacyPolicy />,
        },
        {
          path: "/ReadDetail/:relateInfo",
          element: <ReadDetail />,
        },
        {
          path: "/setNews/:link",
          element: <SetNews />,
        },
      ],
    },
    // {
    //   path: "/setNews/:link",
    //   element: <SetNews />,
    // },
  ],
  {
    basename: "",
  }
);
