import axios from "axios";

const instance = axios.create({
  // baseURL: "https://inv.inventech.co.th/IR_Admin_Service",
  //baseURL: "https://pro.inventech.co.th/IR_DevV2_Service",
   //baseURL: "https://pro.inventech.co.th/IR_Demov2_Service",
  baseURL: "https://pro.inventech.co.th/IR_AdminV2_Service",
  headers: {
    "Content-Type": "text/json",
    apiKey: "bdda740b-eed5-4949-8f50-278578fff6e7",
  },
});

export default instance;
