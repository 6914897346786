import React, { useState } from "react";
import "../../../CSS/FkCss/Financial.css";

import { useGlobalContext } from "../../../context/Context";
import {
  FinancialDownloadDataTH,
  FinancialDownloadDataEN,
} from "../../../data/FinancialDownloadData";
import { Header } from "../../../Components";

export default function FinancialStatement() {
  const { language } = useGlobalContext();
  const nowYear = new Date().getFullYear();

  const yearList = [2024, 2023, 2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015];
  const [year, setYear] = useState(2024);

  // const [yearEN, setYearEN] = useState(2024);
  // const seenYears = new Set();
  // const seenYearsEN = new Set();
  const convertYear = (year, language) => {
    if (language === "T") {
      return year + 543;
    }
    return year;
  };

  return (
    <div className="sub-top-hight">
      <div className="container-I" style={{ marginBottom: 100 }}>
        <div className="row">
          <div className="box-line-c">
            <Header nameTH={"งบการเงิน"} nameEN={"Financial statements"} />
          </div>
        </div>

        <div
          className="container mt-4"
          style={{ margin: "auto", marginTop: "3.75rem" }}
        >
          <div className="select-year select-year-fn">
            <p className="select-1 max-[420px]:text-base">
              {language === "T" ? "เลือกปี :" : "Select Year:"}
            </p>
            <select
              className="select-box select-1 max-[420px]:text-sm"
              style={{ paddingLeft: 10 }}
              value={year}
              onChange={(e) => setYear(e.target.value)}
            >
              {yearList.map((item) => (
                <option key={item} value={item}>
                  {convertYear(item, language)}
                </option>
              ))}
            </select>
          </div>

          <div className="container-financial">
            <div className="container-select-financial">
              {language === "T"
                ? FinancialDownloadDataTH.map(
                    (item) =>
                      item.yearEN == year && (
                        <div className="column-fn-1" key={item.id}>
                          <div className="hexagon" id="hexagon">
                            <div className="text-p-span">
                              <p className="text-p">งบการเงิน</p>
                              <span>{item.namefn}</span>
                            </div>
                            <a href={item.filefn}>
                              <button className="contact-bt-2 btn-fn">
                                ดาวน์โหลด
                              </button>
                            </a>
                          </div>
                        </div>
                      )
                  )
                : FinancialDownloadDataEN.map(
                    (item) =>
                      item.year == year && (
                        <div className="column-fn-1" key={item.id}>
                          <div className="hexagon">
                            <div className="text-p-span">
                              <p>Financial statements</p>
                              <span>{item.namefn}</span>
                            </div>
                            <a href={item.filefn}>
                              <button className="contact-bt-2 btn-fn">
                                Download
                              </button>
                            </a>
                          </div>
                        </div>
                      )
                  )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
